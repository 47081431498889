<template>
  <section style="max-width: 100rem; margin: auto;">
    <!-- title -->
    <div class="text-center">
      <h1 class="mt-5">
        Pricing List
      </h1>

      <p class="mb-2 pb-75">
      </p>
      <b-button-toolbar class="text-center"  style="justify-content: center; display: flex; padding: 5px;" pill>
        <!-- <b-button-group
            size="lg"
            pill
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="background-color: silver;" 
              @click="changePrice(1)"
            >
              1 Month
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="changePrice(2)"
            >
              2 Month
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="changePrice(3)"
            >
              3 Month
            </b-button>
          </b-button-group> -->
        </b-button-toolbar>
    </div>
    <br></br>
    <!-- Payment method -->
    <b-row> 
      <b-col
        sm="12"
        md="12"
        lg="12"
        class="mx-auto"
        center
      >
        <b-row class="match-height justify-content-md-center">

          <b-col md="3" center>
            <b-card
              align="center"
              no-body
            >
              <b-card-header style="background-color: silver;" class="text-center">
                <h3 style="color:black;">Plan 1</h3>
              </b-card-header>
              <b-card-body style="height: 200px; padding-top: 70px;">
                <h1><b style="color:aqua">1</b> Key</h1>
                <h6>From 1 to < 5 Key</h6>
              </b-card-body>
              <b-card-footer>
                <h6>Residential proxies</h6>
                <b>{{price.t1}}$</b>/Key
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col md="3" center>
            <b-card
              align="center"
              no-body
            >
              <b-card-header style="background-color: silver;">
                <div>
                  <h3 style="color:black">Plan 2</h3>
                </div>
                
              </b-card-header>
              <b-card-body style="height: 200px; padding-top: 70px;">
                <h1><b style="color:aqua">5</b> Key</h1>
                <h6>From 5 to < 10 Key</h6>
              </b-card-body>
              <b-card-footer>
                <h6>Residential proxies</h6>
                <b>{{price.t5}}$</b>/Key
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col md="3" center>
            <b-card
              align="center"
              no-body
            >
              <b-card-header style="background-color: silver;">
                <div>
                  <h3 style="color:black">Plan 3</h3>
                </div>
                
              </b-card-header>
              <b-card-body style="height: 200px; padding-top: 70px;">
                <h1><b style="color:aqua">10</b> Key</h1>
                <h6>From 10 to < 20 Key</h6>
              </b-card-body>
              <b-card-footer>
                <h6>Residential proxies</h6>
                <b>{{price.t10}}$</b>/Key
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col md="3" center>
            <b-card
              align="center"
              no-body
            >
              <b-card-header style="background-color: silver;">
                <div>
                  <h3 style="color:black">Plan 4</h3>
                </div>
                
              </b-card-header>
              <b-card-body style="height: 200px; padding-top: 70px;">
                <h1><b style="color:aqua">20</b> Key</h1>
                <h6>From 10 to < 20 Key</h6>
              </b-card-body>
              <b-card-footer>
                <h6>Residential proxies</h6>
                <b>{{price.t20}}$</b>/Key
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col md="3" center>
            <b-card
              align="center"
              no-body
            >
              <b-card-header style="background-color: silver;">
                <div>
                  <h3 style="color:black">Plan 5</h3>
                </div>
                
              </b-card-header>
              <b-card-body style="height: 200px; padding-top: 70px;">
                <h1><b style="color:aqua">50</b> Key</h1>
                <h6>From 50 to < 100 Key</h6>
              </b-card-body>
              <b-card-footer>
                <h6>Residential proxies</h6>
                <b>{{price.t50}}$</b>/Key
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col md="3" center>
            <b-card
              align="center"
              no-body
            >
              <b-card-header style="background-color: silver;">
                <div>
                  <h3 style="color:black">Plan 6</h3>
                </div>
                
              </b-card-header>
              <b-card-body style="height: 200px; padding-top: 70px;">
                <h1><b style="color:aqua">100</b> Key</h1>
                <h6>From 100 to < 200 Key</h6>
              </b-card-body>
              <b-card-footer>
                <h6>Residential proxies</h6>
                <b>{{price.t100}}$</b>/Key
              </b-card-footer>
            </b-card>
          </b-col>

          <b-col md="3" center>
            <b-card
              align="center"
              no-body
            >
              <b-card-header style="background-color: silver;">
                <div>
                  <h3 style="color:black">Plan 7</h3>
                </div>
                
              </b-card-header>
              <b-card-body style="height: 200px; padding-top: 70px;">
                <h1><b style="color:aqua">200</b> Key</h1>
                <h6>From 200 to < 500 Key</h6>
              </b-card-body>
              <b-card-footer>
                <h6>Residential proxies</h6>
                <b>{{price.t200}}$</b>/Key
              </b-card-footer>
            </b-card>
          </b-col>


          <b-col md="3" center>
            <b-card
              align="center"
              no-body
            >
              <b-card-header style="background-color: silver;">
                <div>
                  <h3 style="color:black">Plan 7</h3>
                </div>
                
              </b-card-header>
              <b-card-body style="height: 200px; padding-top: 70px;">
                <h1><b style="color:aqua">500</b> Key</h1>
                <h6>Greater than 500 Key</h6>
              </b-card-body>
              <b-card-footer>
                <h6>Residential proxies</h6>
                <b>{{price.t200}}$</b>/Key
              </b-card-footer>
            </b-card>
          </b-col>
          
        </b-row>
      </b-col>a
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BImg, BButton, BCardText, BListGroup,BListGroupItem, BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormInput, BBadge, BCardHeader, BCardBody, BCardFooter, BButtonToolbar, BButtonGroup} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AddFundHistory from '../table/AddFundHistory.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
const UsersPaymentRepository = RepositoryFactory.get('userPayment')

export default {
  components: {
    BCard,
    BCardText,
    BRow, 
    BCol,
    BImg,
    BButton,
    BListGroup,
    BListGroupItem,
    AddFundHistory,
    BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
    BFormInput, BBadge,
    BCardHeader,BCardBody, BCardFooter,
    BButtonToolbar, BButtonGroup
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      select: 0,
      amount: 0,
      items:[],
      userInfo: this.$store.getters['auth/userInfo'],
      sett: this.$store.getters['auth/settings'],
      code: 'ck',
      rate: '22000',
      price: []
    }
  },
  created(){
    // UsersPaymentRepository.fetch().then(rs => {
    //   this.items = rs.data.data
    //   // setTimeout(()=>{
    //   //   this.$refs.fhis.mountData()
    //   // },1000)
      
    // })
    this.rate = this.$store.getters['auth/settings'].dollar_to_vnd

    this.price = {
      "t1": this.sett.price_1,
      "t5": this.sett.price_5,
      "t10": this.sett.price_10,
      "t20": this.sett.price_20,
      "t50": this.sett.price_50,
      "t100": this.sett.price_100,
      "t200": this.sett.price_200
    }
    //this.changePrice(1)
  },
  mounted(){
  },
  methods:{
    showModal(selection){
      this.select = selection
      this.$refs.modal.showModal()
    },
    updateFundList(item){
      
      this.items.unshift(item)
    },
    changePrice(s){
      let ex = 1
      switch(s){
        case 1:
          ex = 0
          break;
        case 2:
          ex = 0.1
          break;
        case 3:
          ex = 0.2
          break;
      }

      this.price = {
      "t1": this.sett.T0 + ex,
      "t10": this.sett.T10 + ex,
      "t50": this.sett.T50  + ex,
      "t100": this.sett.T100 + ex,
      "t200": this.sett.T200 + ex,
      "t500": this.sett.T500 +ex,
      "t1000": this.sett.T1000 + ex
    }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
