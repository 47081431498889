<template>
  <b-card body-text-variant="success" class="d-flex justify-content-center mb-1" style="max-width: 100rem; margin: auto;">
    <b-alert
      variant="success"
      show
    >
      <div class="alert-body">
        API KEY:  {{ userInfo.apikey }}
      </div>
    </b-alert>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Plans</span>
        </template>
        <plan-list></plan-list>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Billing</span>
        </template>
        <div v-if="!billings" class="d-flex justify-content-center mb-1">
          <b-spinner label="Loading..." />
        </div>
        <bill-list v-if="billings" :data="billings"></bill-list>
      </b-tab>
      
    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BCardText,BTab,BTabs, BSpinner, BAlert } from 'bootstrap-vue'
import BillList from './BillList.vue'
import PlanList from './PlanList.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
const NPlansRepository = RepositoryFactory.get('nplans')
const BillingsRepository = RepositoryFactory.get('billings')

export default {
  components: {
    BCard,
    BCardText,
    BTab,
    BTabs,
    BSpinner,
    BAlert,
    'bill-list':BillList,
    'plan-list':PlanList
  },
  data(){
    return {
      billings: null,
      plans: null,
      userInfo: this.$store.getters['auth/userInfo'],
    }
  },
  created(){
    this.$gtag.event('route', { path: 'Billing' })
    BillingsRepository.fetch().then(rs=>{
      this.billings = rs.data.data
      // NPlansRepository.fetchAll().then(rs=>{
        
      // }).catch(e=>{

      // })
    }).catch(e=>{

    })
    

  }
}
</script>

<style>

</style>
