<template>
    <b-card>
        <b-card-header>
          <b-card-actions>
            <div class="demo-inline-spacing">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="showModal">
                  Buy New Plan
              </b-button>

              <!-- <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  v-if="checkPlanTest == false" variant="outline-primary" @click="activeTestMode">
                  Active Plan Test
              </b-button> -->
            </div>
          </b-card-actions>
        </b-card-header>

        
        <b-overlay
          :show="show"
          rounded="xl"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
        <b-card-body>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          ref="table"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="data"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <template #cell(actived_at)=data>
            {{convertUTCDateToLocalDate(data.item.actived_at)}}
          </template>
          <template #cell(expired_at)=data>
            {{data.item.expired_at ? convertUTCDateToLocalDate(data.item.expired_at) : ''}}
          </template>



          <template #cell(created_at)=data>
            {{data.item.created_at ? convertUTCDateToLocalDate(data.item.created_at) : ''}}
          </template>

          <template #cell(status)=data>
            <b-badge :variant="getStatusVariant(data.item.status)">{{getStatusName(data.item.status)}}</b-badge> 
          </template>
          <template #cell(renew)=data>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              :variant="data.item.renew ? 'outline-success' : 'outline-danger'"
              class="btn-icon"
              v-b-tooltip.hover.top="'Renew'"
              @click="setRenew(data.item._id)"
            >
              <feather-icon icon="CreditCardIcon" />
            </b-button>
          </template> 

          <template #cell(_id)=data>
            <div class="text-nowrap">
              <b-button
                v-if="data.item.status == 2"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-warning"
                class="btn-icon circle"
                v-b-tooltip.hover.top="'Re-actived'"
                @click="reActive(data.item)"
              >
                <feather-icon icon="CornerDownLeftIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                class="btn-icon circle"
                v-b-tooltip.hover.top="'Manager'"
                :to="'/plans/'+data.item._id"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
             
            </div>
          
          </template>

        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
        </b-overlay>
        <b-modal
          id="modal-buy-bw"
          ok-title="Confirm"
          cancel-title="Cancel"
          size="lg"
          title="Buy Plans"
          @ok="buyPlan"
          no-close-on-backdrop
          :ok-disabled = "ok_dis"
        >
        <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Plan Name"
                  label-for="h-first-name"
                  label-cols-md="3"
                
                >
                  <b-form-input
                    id="h-first-name"
                    placeholder="Plan Name"
                    type="text"
                    v-model="planInfo.name"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Month"
                  label-for="h-first-name"
                  label-cols-md="3"
                
                >
                <v-select
                  id="country"
                  v-model="planInfo.month"
                  :options="month"
                  label="text"
                />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Total Key"
                  label-for="h-first-name"
                  label-cols-md="3"                  
                >
                  <!-- <b-form-input
                    id="h-first-name"
                    placeholder="Range: 1->1000"
                    type="number"
                    v-model="planInfo.qty"
                    :min="1"
                    :max="1000"
                    :step="1"
                  /> -->

                  <b-form-spinbutton
                    id="demo-sb"
                    v-model="planInfo.qty"
                    min="1"
                    max="1000"
                  />
                </b-form-group>
              </b-col>
              
              <b-col cols="12">
                <b-form-group
                  label=""
                  label-for="h-first-name"
                  label-cols-md="3"
                  
                >
                  <h5 style="font-color:success;">{{ desc }}</h5>
                  </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label=""
                  label-for="h-first-name"
                  label-cols-md="3"
                  
                >
                  <b-form-checkbox
                  v-model="planInfo.renew"
                  >Auto Renew</b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
          <iframe src="https://guide.shadowtech.vip/ios/pricelistios.html" frameborder="0" style="overflow:hidden;height:600px;width:100%" width="100%"></iframe>
        </b-modal>

        
    </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect,  BPagination,VBTooltip, BFormCheckbox,BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BFormRadio,
  BRow,BCol, BCardHeader, BForm, BImg, BCardActions, BFormSpinbutton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import pricing from './Pricing1.vue'
import RepositoryFactory from '../../../api/RepositoryFactory'
import nportsRepository from '@/api/users/nportsRepository'
const NPlansRepository = RepositoryFactory.get('nplans')



const tempPObj = {
  bw: 100,
  ptype: 0,
  oid: {},
  month: 1
}

const planParam = {
  name: '',
  qty: 1,
  month: 1
}

//import { PayPalIcon } from 'vue-brand-icons'
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BFormRadio,
    BCardActions,
    BRow,
    BCol,
    BCardHeader, 
    BForm,
    vSelect,
    BImg,
    BFormCheckbox,
    BFormSpinbutton,
    VBTooltip,
    'pricing': pricing
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      data: [],
      show:false,
      variant: 'light',
      opacity: 0.85,
      blur: '2px',
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      //desc: "",
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'oid', label: 'Order ID', sortable: true },
        { key: 'name', label: 'Plan Name', sortable: true },
        { key: 'qty', label: 'Total Key', sortable: true },
        { key: 'month', label: 'Month', sortable: true },
        { key: 'renew', label: 'Auto Renew', sortable: true },
        { key: 'created_at', label: 'Buy Time', sortable: true },
        {key: 'actived_at', label: 'Active Time', sortable: true },
        { key: 'expired_at', label: 'Expired Time', sortable: true },
        {key: "status", label: "Status"},
        { key: '_id', label: 'Action' },
      ],
      /* eslint-disable global-require */
      /* eslint-disable global-require */
      status: [
        {
          0: 'light-success', 1: 'light-danger',
        },
        {
          0: 'Actived', 1: 'Waiting',
        },
      ],

      tempPost: {
        bw: 10,
        ptype: 1,
        oid: {},
        month: 1
      },

      planInfo: {
        name: '',
        qty: 1,
        renew: false,
        month: 1
      },
      month: [1,3,6,12],
      max_bw: "",
      ok_dis: false,
      price_per_key: 1,
      psettings: this.$store.getters['auth/settings'],
    }
  },
  created(){
    
    this.show = false
    NPlansRepository.fetch().then(rs=>{
      this.data = rs.data.data.sort()
    })
    this.price_per_key = this.psettings.price_1
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    desc(){
      const price = this.planInfo.qty * this.getPriceFromQTY(this.planInfo.qty,this.planInfo.month) * this.planInfo.month
      return `Amount: ${price}$ | ${this.getPriceFromQTY(this.planInfo.qty,this.planInfo.month)}$ per key`
    },
    checkPlanTest(){
      console.log(this.data.filter(x=>x.testMode).length)
      return this.data.filter(x=>x.testMode).length > 0
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
   
    showModal(){
      this.tempPost = Object.assign({},tempPObj)
      if(this.data.length > 0){
        this.tempPost.ptype = 0
        this.tempPost.oid = this.data[0]
        this.tempPost.month = this.data[0].month
      }else{
        this.tempPost.ptype = 1
        this.tempPost.oid = {}
        this.tempPost.month = 1
      }
      this.tempPost.ptype = 1
      this.$bvModal.show('modal-buy-bw')
    },
    formatDateTime(d){
      if(!d)
        return
      let date = new Date(d)
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('vi-VN', options).format(date)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    convertUTCDateToLocalDate(d) {
      if(!d)
        return
      let date = new Date(d)
      return date.toLocaleString('vi-VN')
      return this.formatDateTime(date.toLocaleString());   
      var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();

      newDate.setHours(hours - offset);

      return this.formatDateTime(newDate);   
    },
    buyPlan(){

      
      if(this.planInfo.name == ""){
        this.$bvToast.toast("Name can not be empty", {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        return
      }



      if(![1,3,6,12].includes(this.planInfo.month)){
          this.$bvToast.toast("Month incorrect", {
              title: 'Error',
              variant: 'danger',
              solid: false,
            })
          return
        }


      if(parseInt(this.planInfo.qty) <= 0){
        this.$bvToast.toast("QTY incorrect", {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        return
      }

      NPlansRepository.buyPlan({
        name: this.planInfo.name,
        qty: parseInt(this.planInfo.qty),
        renew: this.planInfo.renew,
        month: this.planInfo.month
      }).then(rs=>{
        if (rs.data.success){
          this.data.push(rs.data.data)
          this.$store.dispatch('auth/get_uinfo')

        }else{
          this.$bvToast.toast(rs.data.error, {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        }
      })
    },

    setRenew(id){
      NPlansRepository.setRenew(id).then(rs=>{
        if(rs.data.success){
          const index = this.data.findIndex(x=>x._id == id)
          this.data[index].renew = rs.data.renew

          this.$bvToast.toast("Set renew success", {
            title: 'Success',
            variant: 'success',
            solid: "success",
          })
        }else{
          this.$bvToast.toast(rs.data.error, {
            title: 'Error',
            variant: 'danger',
            solid: false,
          })
        }
      })
    },
    getDate(c){
      let d = new Date(c)
      let t = d.getTime() - new Date(Date.now()).getTime()


      let diffDay =  (t / (1000 * 3600 * 24)).toFixed(0)
      return diffDay+" days"
    },
    calPrice(_price,_percentOff){
        return ((100 - _percentOff) * _price / 100 )
    },
    getPriceFromQTY(qty,month){
      let ex = 1
      switch(month){
        case 1:
          ex = 0
          break;
        case 3:
          ex = 25
          break;
        case 6:
          ex = 50
          break;
        case 12:
          ex = 55
          break;
      }
  

      if(qty > 0 && qty < 5)
      {
        return this.calPrice(this.psettings.price_1,ex)
        //return this.psettings.price_1
      }
      else if(qty >= 5 && qty < 10)
      {
        return this.calPrice(this.psettings.price_5,ex)
      }
      else if(qty >= 10 && qty < 20)
      {
        return this.calPrice(this.psettings.price_10,ex)
      }
      else if(qty >= 20 && qty < 50)
      {
        return this.calPrice(this.psettings.price_20,ex)
      }
      else if(qty >= 50 && qty < 100)
      {
        return this.calPrice(this.psettings.price_50,ex)
      }
      else if(qty >= 100 && qty < 200)
      {
        return this.calPrice(this.psettings.price_100,ex)
      }
      else if(qty >= 200 && qty < 500)
      {
        return this.calPrice(this.psettings.price_200,ex)
      }
      else if(qty >= 500)
      {
        return this.calPrice(this.psettings.price_500,ex)
      }
    },


    getPriceOfPlan(bw_){
      //(bw_)
      if(bw_ == 1000){
        return this.psettings.T1000
      }
      else if(bw_ == 500)
      {
        return this.psettings.T500
      }
      else if(bw_ == 200)
      {
        return this.psettings.T200
      }
      else if(bw_ == 100)
      {
        return this.psettings.T100
      }
      else if(bw_ == 50)
      {
        return this.psettings.T50
      }
      else if(bw_ == 10)
      {
        return this.psettings.T10
      }
      else if(bw_ == 0)
      {
        return  this.psettings.T0
      }
    },
    getInfoPlan(data){
      if(data.date_expired)
        return data.oid +' ' + data.price+"$/GB"+' '+this.getDate(data.date_expired)
      else
        return data.oid +' ' + data.price+"$/GB"
    },
    getStatusName(status){
      switch(status){
        case 0:
          return "Not Actived"
        case 1:
          return "Actived"
        case 2:
          return "Expired"
      }
    },

    getStatusVariant(status){
      switch(status){
        case 0:
          return "warning"
        case 1:
          return "success"
        case 2:
          return "danger"
      }
    },
    // activeTestMode(){
    //   this.$swal({
    //     title: 'Are you sure?',
    //     text: `You can only use test mode once`,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Active Test',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //   }).then(result => {
    //     if (result.value) {
    //       NPlansRepository.enableTestMode().then(rs=>{
    //         if(rs.data.success){
    //           this.data.push(rs.data.data)
    //         }else{
    //           this.$bvToast.toast(rs.data.error, {
    //             title: 'Error',
    //             variant: 'danger',
    //             solid: false,
    //           })
    //         }
    //       })
    //     }
    //   })
    // },
    reActive(id){
      const amout  = this.getPriceFromQTY(id.qty,id.month) * id.qty *id.month
      this.$swal({
        title: 'Are you sure?',
        text: `You need pay ${amout}$ to re-active this plan `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Re-Active',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          NPlansRepository.reactivePlan(id._id).then(rs=>{
            console.log(rs.data)
            if(rs.data.success){
              id.expired_at = rs.data.expired
              id.status = 1

              this.$bvToast.toast("Re-active success", {
                title: 'Success',
                variant: 'success',
                solid: false,
              })
            }else{
              this.$bvToast.toast(rs.data.error, {
                title: 'Error',
                variant: 'danger',
                solid: false,
              })
            }
           
          })
        }
      })
    }

  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';
.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
